import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './Header.module.css';
/*import defaultAvatar from '../../img/ava-ufo.png'; // Import the default avatar*/
import defaultAvatar from '../../img/avatars/hzkto.jpg'; // Import the default avatar
import backArrow from '../../img/back-arrow.png';

const Header = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Получаем текущий путь
    const avatarUrl = localStorage.getItem('avatar') || defaultAvatar;

    const handleBack = () => {
        /*navigate(-1);*/ // Возвращаемся на предыдущую страницу
        console.log(location.pathname);
        switch (location.pathname) {
            case '/postreg':
                navigate('/postreg');
                break;
            case '/postreg2':
                navigate('/postreg2');
                break;
            case '/dash':
                navigate('/dash');
                break;
            case '/step5':
                navigate('/dash');
                break;
            case '/step55':
                navigate('/step5');
                break;
            case '/help/default':
                navigate(-1);
                break;
            case '/profile':
                navigate(-1);
                break;
            case '/preprofile':
                navigate(-1);
                break;
            default:
                navigate('/postreg'); // Значение по умолчанию, если тип не найден
        }
    };

    const handleGoUserProfile = () => {
        navigate("/preprofile");
    };

    const handleImageError = (event) => {
        event.target.src = defaultAvatar;
    };

    // Список страниц, на которых кнопки не должны отображаться
    const hideBackButtonPages = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/postreg', '/postreg2', '/dash', '/regform'];
    const hideAvatarPages = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/regform', '/preprofile'];
    const hideHeader = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/start', '/regform'];

    // Если текущий путь есть в hideHeader, не рендерим компонент
    if (hideHeader.includes(location.pathname)) {
        return null;
    }

    return (
        <div className={styles.header}>
            {!hideBackButtonPages.includes(location.pathname) && (
                <div className={styles.backButton} onClick={handleBack}>
                    <img src={backArrow} alt="Назад" className={styles.backIcon}/>
                </div>
            )}
            <div className={styles.title}>{title}</div>
            {!hideAvatarPages.includes(location.pathname) && (
                <div
                    className={styles.avatarContainer}
                    onClick={handleGoUserProfile}
                >
                    <img
                        src={avatarUrl}
                        alt="Аватар пользователя"
                        className={styles.avatar}
                        onError={handleImageError}  // Обработка ошибки загрузки
                    />
                </div>
            )}
        </div>
    );
};

export default Header;
