import config from "../../../settings/config";

export const sendErrorMail = async (text) => {

    try {
        const response = await fetch(`${config.app.host}/api/v1/mail/send`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({text: text}),
        });

        if (!response.ok) {
            throw new Error(response.status + ': ' + response.statusText);
        }

        return await response;

    } catch (error) {
        throw new Error(error.message);
    }
}
