import React, {useEffect, useRef, useState} from 'react';
import styles from './ConnectorCard.module.css';
import {checkToken} from "../../../Utils/TokenService";
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import BatteryGauge from 'react-battery-gauge'
/*import mode3Type2Image from "../../../../img/type2.webp" // Путь к изображению Mode3 Type2
import CHAdeMoImage from '../../../../img/chademo.webp'; // Путь к изображению CHAdeMo
import GBT2Image from '../../../../img/chademo.webp'; // Путь к изображению GBT2
import CCSImage from '../../../../img/ccs.webp'; // Путь к изображению CCS
import GBTImage from '../../../../img/gbt.webp'; // Путь к изображению GB/T*/
import mode3Type2Image from "../../../../img/connectors/type2.png" // Путь к изображению Mode3 Type2
import CHAdeMoImage from '../../../../img/connectors/chademo.png'; // Путь к изображению CHAdeMo
import GBTImage from '../../../../img/connectors/chademo.png'; // Путь к изображению GB/T
import GBT2Image from '../../../../img/connectors/gbt.png'; // Путь к изображению GBT2
import CCSImage from '../../../../img/connectors/ccs.png';
import {Button, Modal} from "react-bootstrap";


const ConnectorCard = (props) => {
    const {
        connectorId,
        sn,
        status,
        type
    } = props;

    // Определяем картинку на основе типа разъема
    let connectorImage;
    switch (type) {
        case 'CCS':
            connectorImage = CCSImage;
            break;
        case 'GB/T':
            connectorImage = GBTImage;
            break;
        case 'GBT2':
            connectorImage = GBT2Image;
            break;
        case 'Mode3_type2':
            connectorImage = mode3Type2Image;
            break;
        case 'CHAdeMo':
            connectorImage = CHAdeMoImage;
            break;
        default:
            connectorImage = mode3Type2Image; // Значение по умолчанию, если тип не найден
    }

    const config = require('../../../../settings/config.js');
    const token = localStorage.getItem('s_token');
    const [connectorStatus, setConnectorStatus] = useState({
        consumeEnergy: 0
    });
    // Состояние для управления модальным окном1
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    // Состояние для счётчика
    const [counter, setCounter] = useState(90);
    const timerRef = useRef(null);

    const handleModalOpen = () => {
        setCounter(90); // Сбрасываем счётчик при открытии модального окна
        setShowModal(true); // Показываем модальное окно

        // Запускаем таймер
        timerRef.current = setInterval(() => {
            setCounter((prevCounter) => {
                if (prevCounter > 0) {
                    return prevCounter - 1;
                } else {
                    clearInterval(timerRef.current); // Останавливаем таймер, когда счётчик достигает 0
                    setShowModal(false);
                    return 0;
                }
            });
        }, 1000);
    };

    const handleModalClose = () => {
        setShowModal(false);
        clearInterval(timerRef.current); // Очищаем таймер при закрытии модального окна
    };

    useEffect(() => {
        return () => clearInterval(timerRef.current); // Чистим таймер при размонтировании компонента
    }, []);

    useEffect(() => {
        // Если статус стал "Preparing" или "Charging", останавливаем таймер и закрываем модальное окно
        if (status === "Preparing" || status === "Charging") {
            clearInterval(timerRef.current); // Останавливаем таймер
            setShowModal(false); // Закрываем модальное окно
        }
        if (status === "Finishing") {
            setShowModal2(true);
        } else if (status !== "Finishing") {
            setShowModal2(false);
        }
    }, [status]);

    // Чекаем используемые токены
    const navigate = useNavigate();
    useEffect(() => {
        const m_token = localStorage.getItem('s_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    // Функция для старта зарядки
    const handleStart = async () => {
        try {
            const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/start?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                toast.success('Charging started', {autoClose: 3000});
                if (status === 'Available') {
                    handleModalOpen();
                }
            } else {
                toast.error('Failed to start charging', {autoClose: 3000});
            }
        } catch (error) {
            toast.error(`Error: ${error}`, {autoClose: 3000});
        }
    };

    // Функция для остановки зарядки
    const handleStop = async () => {
        try {
            const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/event/stop?sn=${sn}&connectorId=${connectorId}&limit=1200`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                toast.success('Charging stopped', {autoClose: 3000});
            } else {
                toast.error('Failed to stop charging', {autoClose: 3000});
            }
        } catch (error) {
            toast.error(`Error: ${error}`, {autoClose: 3000});
        }
    };

    // Функция для опроса состояния коннектора каждые 15 секунд
    useEffect(() => {
        const fetchConnectorStatus = async () => {
            // Проверяем токенг перед каждым запросом
            if (!checkToken(token)) {
                navigate('/step2');
            }
            if (status === 'Charging') {
                try {
                    const response = await fetch(`${config.skuns.protocol}://${config.skuns.ip}:${config.skuns.port}/pss/api/v1/info/last-transaction?sn=${sn}&type=${type}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();
                    setConnectorStatus(data);
                    console.log(data);
                } catch (error) {

                }
            }
        };
        // Первоначальный вызов и интервал
        if (status === 'Charging') {
            fetchConnectorStatus();
            const intervalId = setInterval(fetchConnectorStatus, 15000);
            return () => clearInterval(intervalId);
        }

        // Очистка данных, если статус больше не "Charging"
        if (status !== 'Available') {
            setConnectorStatus({
                consumeEnergy: 0
            });
        }
    }, [status, token]);

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        //const secs = seconds % 60;

        if (hrs > 0) {
            return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
        } else {
            return `00:${mins.toString().padStart(2, '0')}`;
        }
    };

    const typeTransform = (type) => {
        switch (type) {
            case 'CCS':
                return 'CCS';
            case 'GB/T':
                return 'GB/T';
            case 'GBT2':
                return 'GB/T2';
            case 'Mode3_type2':
                return 'Type2';
            case 'Mode3_type1':
                return 'Type1';
            case 'CHAdeMo':
                return 'CHAdeMo';
            default:
                return type;
        }
    };

    return (
        <div className={`${styles.card}`}
             onClick={() => {
             }}>
            <div className={styles.infoSection}>
                <h3>{typeTransform(type)}</h3>
                <p>{status}</p>
                {status !== "Unavailable" ? // Скрываем кнопку, если статус "Unavailable"
                    <small className={styles.infoText}>
                        <button type="button" className="btn btn-outline-secondary btn-sm"
                                onClick={status === "Charging" ? handleStop : handleStart}> {status === "Charging" ? "Stop" : "Start"}
                        </button>
                    </small> : <div></div>
                }
            </div>

            {status === "Available" ?
                <div className={styles.infoSection}>
                    <div>Подключите<br/> коннектор<br/> к автомобилю</div>
                </div> : <div></div>
            }

            {status === "Charging" ?
                <div className={styles.infoSection}>
                    <BatteryGauge value={40} size={90} animated={true} charging={true} aspectRatio={0.3}/>
                    <p>Ток: {connectorStatus?.amperage} A<br/>
                        Мощность: {connectorStatus?.power} кВт<br/>
                        Заряжено: {connectorStatus?.consumeEnergy / 1000} кВт.ч<br/>
                        Время: {formatTime(connectorStatus?.chargeTime || 0)}</p>
                </div> : <div></div>
            }

            {status === "Finishing" ?
                <div className={styles.infoSection}>
                    <p>Заряжено: <b>{connectorStatus?.consumeEnergy / 1000} кВт.ч</b><br/>
                        Время: <b>{formatTime(connectorStatus?.chargeTime || 0)}</b></p>
                </div> : <div></div>
            }

            {status === "Preparing" ?
                <div className={styles.infoSection}>
                    <div>Подготовка к зарядке...</div>
                </div> : <div></div>
            }

            {status === "Unavailable" ?
                <div className={styles.infoSection}>
                    <div>Коннектор недоступен</div>
                </div> : <div></div>
            }

            <div className={styles.imageSection}>
                <img src={connectorImage} className={styles.stationImage} alt="q"/>
            </div>
            <Modal show={showModal} onHide={handleModalClose} centered backdrop="static">
                <Modal.Header className={styles.modalHeader}>
                    <Modal.Title>Подключите коннектор к автомобилю!</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.counter}><b>{counter}</b></Modal.Body>
            </Modal>
            {/* Модальное окно2 */}
            <Modal show={showModal2} onHide={() => setShowModal2(false)} centered>
                <Modal.Header className={styles.mod2header}>
                    <Modal.Title>Зарядка окончена</Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.mod2text}>Отключите коннектор от автомобиля!<br/> Счастливого
                    пути!</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowModal2(false)}>
                        Ок
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ConnectorCard;
