import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './RegistrationStep2.module.css'
import {CountrySelector, defaultCountries, parseCountry} from "react-international-phone";
import 'react-international-phone/style.css';
import InputMask from "react-input-mask";
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import {AuthContext} from "../../../context/AuthContext";
import HelpIcon from "../HelpIcon/HelpIcon";
import {getOtpCode} from "../FetchServises/GetOtpCodeService";
import {fetchPKCEParams} from "../VkAuth/FetchPKCEParams";
import {fetchTokens} from "../VkAuth/FetchTokens";

/*
VKID.Config.init({
    app: 52225543, // Идентификатор приложения.
    redirectUrl: 'http://localhost', // Адрес для перехода после авторизации.
    state: 'dj29fnsadjsd82', // Произвольная строка состояния приложения.
    codeVerifier: 'FGH767Gd65', // Верификатор в виде случайной строки. Обеспечивает защиту передаваемых данных.
    scope: 'email phone', // Список прав доступа, которые нужны приложению.
    mode: VKID.ConfigAuthMode.Redirect // По умолчанию авторизация открывается в новой вкладке.
});
*/

const countries = defaultCountries.filter((country) => {
    const {iso2} = parseCountry(country);
    return ['ru', 'by'].includes(iso2);
});


const RegistrationStep2 = (props) => {
    const config = require('../../../settings/config.js');

    const navigate = useNavigate();

    const {setAuthData} = useContext(AuthContext);

    const [phone, setPhone] = useState(() => {
        return localStorage.getItem("phone") ? localStorage.getItem("phone") : ''
    });
    const [country, setCountry] = useState({
        name: 'Russia',
        iso2: 'ru',
        dialCode: '7',
        format: '(___) ___-__-__',
        priority: undefined
    });
    const [phoneMask, setPhoneMask] = useState('+7 (999) 999-99-99'); // {name: 'Goland', iso2: 'pl', dialCode: '48', format: '...-...-...', priority: undefined, …}
    const [placeholder, setPlaceHolder] = useState('+7 ');
    const [isCanChangeKeys, setCanChangeKeys] = useState(false);

    const inputRef = useRef(null);

    // Тут боремся за стиль кнопки при загрузке страницы и когда в localStorage уже есть номер телефона
    const [buttonClass, setButtonClass] = useState(styles.smsButton); // начальный стиль кнопки
    useEffect(() => {
        changeStylePhoneInput(18);
    }, [phone]); // Следим за изменением phone

    useEffect(() => {
        changeStylePhoneInput(11);
    }, []); // Следим за изменением phone

    const changeStylePhoneInput = (length) => {
        // Проверяем длину телефона и обновляем стиль кнопки
        if (phone.length === length) {
            setButtonClass(styles.smsButtonActive); // Активный стиль
        } else {
            setButtonClass(styles.smsButton); // Стандартный стиль
        }
    }


    function handleSelectCountry(e) {
        setCountry(e);
        let phoneMask = '';
        if (e.format) {
            let reFormat = e.format.replace(/\./g, "9");
            phoneMask = '+' + e.dialCode + ' ' + reFormat;
        } else {
            phoneMask = '+\\' + e.dialCode;
        }
        setPhoneMask(phoneMask);
        /*setPhoneMask(e.format ? '+' + e.dialCode + ' ' + e.format : '+' + e.dialCode);qq*/
        setPlaceHolder('+' + e.dialCode);
        setPhone('');

        if (inputRef.current) {
            inputRef.current.focused = true;
            inputRef.current.setCursorToEnd();
            inputRef.current.getInputDOMNode().select();
        }
    }

    function handleInputPhone(e) {
        setPhone(e.target.value);
    }

    async function handleGetSMSButton(e) {
        let resp = '';
            localStorage.setItem('phone', phone.replace(/\D/g, ''));
            try {
                resp = await getOtpCode(phone)
            } catch (error) {
                console.log(error.message);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }
            let pass = "";
            if (resp.showInToast === "yes") {
                pass = resp.token;
            }
            // End request SMS code
            toast.success(`Вам отправлен SMS-код ${pass}`, {
                /*toast.success(`Вам отправлен SMS-код ${resp.token}`, {*/
                position: "top-center",
                autoClose: 2500
            });
            navigate('/step3');
    }

    async function handleLoginOnVK() {
        localStorage.setItem('vkloginprocess', 'true');
        try {
            fetchPKCEParams();
        } catch (error) {
            console.log(error);
        } finally {
            setCanChangeKeys(true);
        }
    }

    useEffect(() => {
        // отправляем запрос на бэк для обмена ключами
        const query = window.location.search;
        const params = new URLSearchParams(query);
        const code = params.get('code');
        async function getVKToken() {
            try {
                const resp = await fetchTokens();
                setAuthData({
                    avatar: resp.user.avatar// сохраняем URL аватарки
                });

            } catch (error) {
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }

            window.history.replaceState(null, '', window.location.pathname);
            navigate('/pinoffer');
            toast.success(`Успешный вход!`, {
                position: "top-center",
                autoClose: 1000
            });
        }

        if (code && !isCanChangeKeys) {
            getVKToken();
        }
    }, [isCanChangeKeys]);

    async function handleLogoutVK() {
        let accessToken = localStorage.getItem('vk_access_token');
        let resp = '';

        if (accessToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/vk/logout`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            access_token: accessToken,
                        }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok in vk/logout');
                }

                resp = await response.json();
                if (resp.error) {
                    toast.error('Ошибка:' + resp.error, {
                        position: "top-center",
                        autoClose: 3000
                    });
                    return;
                }

            } catch (error) {
                toast.error('Что-то пошло не так in vk/logout', {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }
            // End request SMS code
            toast.success(`Logout successfuly!`, {
                position: "top-center",
                autoClose: 3000
            });
        }

    }


    return (
            <div className={styles.container}>
                {/*start row block -- "Page title" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.titleText}>Зарегистрируйтесь <br/> или войдите в PSS Charge</div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block*/}

                {/*start row block -- "input your phone number text" */}
                <div className={styles.column1}>{/*r1c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.textPhoneDiv}>Введите номер телефона</div>
                </div>
                <div className={styles.column3}>{/*r1c3*/}</div>
                {/*end row block*/}

                {/*// ToDo: После выбора страны, фокус ввода перемещать на поле ввода номера телефона, в конец строки (позицию курсора, чтобы можно было сразу набирать номер*/}
                {/*start row block -- input field for phone number */}
                <div className={styles.row1}>{/*r2c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.phoneContainer}>
                        <div className={styles.selectCountryFlagDiv}>
                            <CountrySelector
                                selectedCountry={country.iso2}
                                value={country}
                                countries={countries}
                                onSelect={handleSelectCountry}
                            />
                        </div>
                        <div className={styles.inputPhoneDiv}>
                            <InputMask
                                type="tel"
                                mask={phoneMask}
                                placeholder={placeholder}
                                maskChar={null}
                                value={phone}
                                onChange={handleInputPhone}
                                className={phone.length !== 18 ? `${styles.input}` : `${styles.inputSuccess}`}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r2c3*/}</div>
                {/*end row block*/}

                {/*start row block -- get SMS button*/}
                <div className={styles.row1}>{/*r3c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.getSmsButton}>
                        <button
                            /*className={phone.length !== 18 ? `${styles.smsButton}` : `${styles.smsButtonActive}`}*/
                            className={buttonClass}
                            /*className={styles.smsButton}*/
                            onClick={handleGetSMSButton}
                        >Получить СМС-код
                        </button>
                    </div>
                </div>
                <div className={styles.column3}>{/*r3c3*/}</div>
                {/*end row block*/}

                {/*start row block -- SVG image horizontal line with "or" */}
                <div className={styles.row1}>{/*r4c1*/}</div>
                <div className={styles.column2}>
                    <div className={styles.or}>
                        {/*"OR-IMAGE" on background*/}
                    </div>
                </div>
                <div className={styles.column3}>{/*r4c3*/}</div>
                {/*end row block*/}

                {/*start row block -- continue with Apple button*/}
                <div className={styles.row1}>{/*r5c1*/}</div>
                <div className={styles.column2}>
                    <div className={`${styles.vkButton} ${styles.buttons}`} onClick={handleLoginOnVK}>
                        {/* <button onClick={handleLoginOnVK} style={{width: '100%'}} className={styles.buttons}>Войти через
                            VK ID
                        </button>*/}
                        {/* &nbsp;&nbsp;
                        <button onClick={handleLoginOnVK}>Get VK User info</button>
                        &nbsp;&nbsp;
                        <button onClick={handleLogoutVK}>Logout VK ID</button>*/}
                        {/*"continue with Apple Button" on background*/}
                    </div>

                </div>
                <div className={styles.column3}>{/*r5c3*/}</div>
                {/*end row block*/}

                {/*start row block --- continue with Google Button*/}
                <div className={styles.row1}>{/*r6c1*/}</div>
                <div className={styles.column2}>
                    {/*<div className={`${styles.googleButton} ${styles.buttons}`} onClick={() => {
                        navigate("/dash")
                    }}>
                        "continue with Google Button" on background
                    </div>*/}
                </div>
                <div className={styles.column3}>{/*r6c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r7c1*/}</div>
                <div className={styles.column2}>
                    {/* <div className={`${styles.emailButton} ${styles.buttons}`}>
                        "continue with Email Button" on background
                    </div>*/}
                </div>
                <div className={styles.column3}>{/*r7c3*/}</div>
                {/*end row block*/}

                {/*start row block --- enter through  email Button*/}
                <div className={styles.row1}>{/*r8c1*/}</div>
                <div className={styles.column2}>
                    <div>
                        <div className={styles.privacyText}>
                            Продолжая, вы соглашаетесь <u>с политикой <br/>
                            конфиденциальности</u> приложения и <u>правилами <br/>
                            компании</u>
                            {/* <div className={styles.container}>
                                <p>code: {searchParams.get("code")}</p><br/>
                                <p>expires_in: {searchParams.get("expires_in")}</p><br/>
                                <p>device_id: {searchParams.get("device_id")}</p><br/>
                                <p>state: {searchParams.get("state")}</p><br/>
                                <p>type: {searchParams.get("type")}</p><br/>
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div className={styles.column3}>{/*r8c3*/}</div>
                {/*end row block*/}
                <HelpIcon/>
            </div>
        );
    }
;

export default RegistrationStep2;
