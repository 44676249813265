export async function fetchTokens() {
    const config = require('../../../settings/config.js');

    const query = window.location.search;
    const params = new URLSearchParams(query);
    const code = params.get('code');
    const deviceId = params.get('device_id');
    const state = params.get('state');
    const type = params.get('type');

    try {
        const response = await fetch(`${config.app.host}/api/v1/vk/token`, { // TODO Переименовать конец
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    code: code,
                    deviceId: deviceId,
                    state: state,
                    type: type,
                }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok get vk token');
        }

        let resp = await response.json();
        localStorage.setItem('userId', resp.user.user_id);
        localStorage.setItem('firstName', resp.user.first_name);
        localStorage.setItem('lastName', resp.user.last_name);
        localStorage.setItem('phone', resp.user.phone);
        localStorage.setItem('avatar', resp.user.avatar);
        localStorage.setItem('email', resp.user.email);
        localStorage.setItem('verified', resp.user.verified);
        localStorage.setItem('birthday', resp.user.birthday);
        localStorage.setItem('m_token', resp.m_token);
        localStorage.setItem('s_token', resp.s_token);
        localStorage.setItem('groupId', resp.user.phone);

        return resp;

    } catch (error) {
        console.log(error);
        throw new Error(error.message);
    }
}