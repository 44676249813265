import * as VKID from "@vkid/sdk";

export function fetchPKCEParams() {
    const config = require('../../../settings/config.js');
    try {
        fetch(`${config.app.host}/api/v1/vk/pkce-params`, {
            mode: 'cors',
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        })
            .then(response => response.json())
            .then(responseJson => {
                console.log(responseJson);
                VKID.Config.init({
                    app: config.vk.clientId, // Идентификатор приложения.
                    redirectUrl: config.vk.redirectUrl, // Адрес для перехода после авторизации.
                    state: responseJson.state, // Произвольная строка состояния приложения.
                    codeVerifier: responseJson.codeChallenge, // Верификатор в виде случайной строки. Обеспечивает защиту передаваемых данных.
                    scope: 'email phone', // Список прав доступа, которые нужны приложению.
                    mode: VKID.ConfigAuthMode.InNewTab // По умолчанию авторизация открывается в новой вкладке.
                });
                VKID.Auth.login();
            });

    } catch (error) {
        console.log(error);
        throw new Error(error.message);
    }
}